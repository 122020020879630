.img-logo {
    width: 100px;
}

.div-ul-navbar {
    gap: 15px;
    justify-content: flex-end !important;
}

.nav-item>.active {
    color: #29487d !important;
}

.nav-link {
    font-weight: 500 !important;
    font-size: 13px !important;
    line-height: 1.4 !important;
    /* color: #979797 !important; */
}

.activecolor {
    color: #29487d !important;
}

.non-activecolor {
    color: #979797 !important;
}

.hl {
    border-bottom: 3px solid #29487d;
    width: 28px;
    display: flex;
    text-align: center;
}

.div_line_hl {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.activeclasslinediv {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.activeclassh1 {
    border-bottom: 3px solid #29487d;
    width: 28px;
    display: flex;
    text-align: center;
}

.dropdown_mega_menu {
    border-radius: 10px !important;
    max-width: 585px !important;
    width: 100% !important;
    z-index: 999 !important;
}

.div_main_tabs_heading_navbar {
    max-width: 585px;
    gap: 10px;
    overflow-x: scroll;
}

.div_main_tabs_heading_navbar::-webkit-scrollbar {
    display: none;
}

.div_h4_text_tab_common_navbar {
    width: 80px;
}

.div_drop_down_menu_services {
    display: none;
}


.showmobilemenu {
    display: block !important;
    margin-bottom: 20px;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 4px 4px 2px;
    z-index: 9999;
    padding: 20px;
    padding: 20px 20px 40px 20px;
    min-height: 290px;
    max-height: 82vh;
    overflow-y: scroll;
}

.showmobilemenu::-webkit-scrollbar {
    display: none;
}

.mySidenav {
    /* display: block !important;
    z-index: 9999;
    padding: 20px;
    margin-bottom: 20px;
    height: 100%;
    width: 300px;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #111;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px; */
}

.logo-icon {
    /* width: 100px; */
}

.showmobilemenu>ul {
    align-items: center !important;
}

.displaynonemobile {
    display: none !important;
}

.btn_toggler_navmenu:focus {
    box-shadow: none !important;
}

.btn_toggler_navmenu:focus-visible {
    outline: none !important;
}

.btn_toggler_navmenu:focus {
    outline: none !important;
}

.div_span_cross_icon {
    /* width: 30px !important; */
    /* height: 30px; */
    /* margin-top: 10px; */
    font-size: 20px;
}

@media screen and (max-width: 1500px) {

    .div-ul-navbar {
        gap: 15px;
    }

    .dropdown_mega_menu {
        max-width: 520px !important;
        width: 100% !important;
    }

    .div_main_tabs_heading_navbar {
        max-width: 520px !important;
        width: 100% !important;


    }
}

@media screen and (max-width: 1200px) {

    .div-ul-navbar {
        gap: 15px;
    }

    .dropdown_mega_menu {
        max-width: 520px !important;
        width: 100% !important;
    }

    .div_main_tabs_heading_navbar {
        max-width: 520px;
        width: 100% !important;


    }
}

@media screen and (max-width: 992px) {

    .dropdown_mega_menu {
        display: none !important;
    }

    .div-ul-navbar {
        justify-content: flex-start !important;
    }

    .li-navbar-item {
        /* height: 40px; */
        width: 100%;
    }

    .div-ul-navbar {
        gap: 5px;
    }

    .div_drop_down_menu_services {
        display: flex;
    }

    .navbar-brand {
        height: 67px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }

}