.parentdiv_contactus {
    /* background-image: url("../images/contactus-bg.png");
    background-repeat: no-repeat;
    background-position: right; */
    /* padding-right: 180px; */
}

.parent_div_form {
    /* background: #f9f9f9; */
    background: #d1d1d1;
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    padding: 45px 39px;
    max-width: 703px;
    width: 100%;
}

.inner_div_form {
    gap: 18px;
}

.input_field_contact {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 25px;
    max-width: 625px;
    width: 100%;
    font-size: 11px !important;
    line-height: 1.6 !important;
}

.input_field_contact_padding {
    padding: 17px 32px;

}


.input_field_contactform_modal {
    padding: 5px 15px;
}

.input_field_contact:focus-visible {
    outline: none !important;
}

.input_field_contact::placeholder {
    /* color: #b7b7b7 !important;
    font-weight: 400 !important;
    font-size: 11px !important;
    line-height: 1.4 !important; */
}

.text_field_contactus_modal {
    padding: 5px 15px;
}

.btn_submit_form_contact {
    background: #29487d;
    border-radius: 25px;
    padding: 16px 74px;
    border: none !important;
}

.horizontal_line {
    border: 1px solid rgba(151, 151, 151, 0.22);
}

.div_header_icons_text {
    max-width: 637px;
    width: 100%;
    /* padding-left: 180px; */
}

.div_four_icons_contact {
    margin-top: 55px;
}

.circel_icon_div_contactus {
    background: rgba(56, 85, 134, 0.4);
    border-radius: 50px;
    /* padding: 16px; */
    width: 28px;
    height: 28px;
    /* padding: 10px; */
}

.circel_icon_div_modal {
    background: #385586;
    border-radius: 50px;
    /* padding: 16px; */
    width: 53px;
    height: 53px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.img_circle_modal {
    width: 22px;
    height: 22px;
}

.img_contactusimage {
    max-width: 699.5px;
    width: 100%;
}

.parent_div_circle_icon_text_contactus_first {
    width: 287px;
}

.circel_icon_div_contactus_first {
    background: #385586;
    border-radius: 50px;
    /* padding: 16px; */
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.img_circle_contactus_first {
    width: 24px;
    height: 24px;
}

.div_end_contact_us {
    background: #F9F9F9;
    border-radius: 0px;
}

@media screen and (max-width: 600px) {
    .div_header_icons_text {
        padding-right: 0px !important;
    }

    .parent_div_form {
        padding: 45px 15px;
    }

    .div_parent_data_contactus_first {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .circel_icon_div_modal {

        width: 30px;
        height: 30px;

    }

    .circel_icon_div_modal>svg {
        width: 15px;
        height: 15px;
    }

    .img_circle_modal {
        width: 10px;
        height: 10px;
    }

    .icon_title_contact_modal {
        font-weight: 400 !important;
        font-size: 9px !important;
        line-height: 1.4 !important;
    }

    .icon_text_contact_modal {
        font-weight: 500 !important;
        font-size: 9px !important;
        line-height: 14px !important;
    }

    .contentSecond {
        padding: 25px 15px 36px 15px !important;
    }

    .contentSecond {
        margin: 20px 10px !important;
    }
}

@media screen and (max-width: 410px) {
    .div_icon_title_text_contact_modal {
        width: 86px !important;
    }

}