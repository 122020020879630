.div_commonwrapper {
    padding-left: 180px !important;
    padding-right: 180px !important;
}

.div_container {
    padding: 0px !important;
}

.span_easy_step {
    color: rgb(80, 104, 147) !important;
}

.color29 {
    color: #29487d !important;
}

.widthtext2 {
    max-width: 933px;
    width: 100%;
}

.div_card_follow {
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.06);
}

.div_card_follow_step {
    max-width: 374px;
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.06);
    height: max-content;
}

.div_card_blogheader {
    max-width: 299px;
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.06);
}

.div_card_recent_article {
    max-width: 506px;
    width: 100%;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 10px;
}

.image_card_follow {
    max-width: 347px;
    width: 100%;
    border-radius: 10px 10px 0px 0px;
}

/* start common Button */

.style_parent_div_btn {
    padding: 17px 31px;
}

.style_parent_div_btn_sectionyearexperience {
    max-width: 133px;
    width: 100%;
    height: 44px;
    padding: 0px 15px 0px 18px;


}

.commonbtnstyle_landing_blogs {
    height: 44px;
    padding: 0px 22px;
}

.style_parent_div_btn_ourservices {
    max-width: 133px;
    height: 44px;
    width: 100%;
    padding: 0px 15px 0px 18px;
}

.commonbtnstyle_our_blogs {
    min-height: 32.07px;
    padding: 0px 19px;
}

.btn_readmore_recentarticle_card_right_side {
    padding: 0px 16px;
    width: 133px;
    height: 44px;
}

.btn_readmore_recentarticle_card {
    /* min-height: 24.38px; */
    padding: 0px 6px;
    width: 91.72px;
    height: 28px;
    /* height:px; */
}

.p_date_cardleft_recent {
    max-width: 134px;
    width: 100%;
}

.parent_div_common_btn {
    background: #29487d;
    border-radius: 27.5px;
    position: relative;
}

.btn_common_ {
    background: #29487d;
    border-radius: 27.5px;
    border: none !important;
    color: #fff !important;
}

.fixedTop {
    position: fixed !important;
    width: 100% !important;
    top: 0;
}

/* end common Button */

/* Common tabs */

.div_main_tabs_heading {
    gap: 25px;
}

.div_main_tabs_heading::-webkit-scrollbar {
    display: none;
}

.div_h4_text_tab_common {
    width: 120px;
    cursor: pointer;

}

.h4_text_tab_common:hover {
    color: #29487d !important;

}

.hovertabcontentnavbar:hover {
    color: #29487d !important;

}

.h4_tabs_mage_menu:hover {
    color: #29487d !important;
}

/* end Commmon tabs */

/*  */

.commonbtnsubmit {
    border-radius: 25px;
}

.div_card_ourblogs_landing {
    height: 380px;
    overflow: hidden;
}

.div_card_ourblogs_landing:hover {
    box-shadow: 0 0 20px 2px #29487d;
}

.div_blog_card {
    height: 100%;
}

/*  */

@media screen and (min-width: 300px) and (max-width: 1920px) {
    .div_container_wrapper {
        max-width: 1920px !important;
        padding: 0px !important;
    }
}

@media screen and (max-width: 1500px) {
    .div_commonwrapper {
        padding-left: 80px !important;
        padding-right: 80px !important;
    }

    .div_main_tabs_heading {
        width: 850px;
        overflow-x: scroll;
    }

    .div_main_tabs_heading::-webkit-scrollbar {
        display: none;
    }

    .p_date_cardleft_recent {
        max-width: 70px;
        width: 100%;
    }
}

@media screen and (max-width: 1024px) {
    .div_main_tabs_heading {
        width: 550px;
    }

}

@media screen and (max-width: 900px) {
    .div_commonwrapper {
        padding-left: 40px !important;
        padding-right: 40px !important;
    }


    .div_main_tabs_heading {
        gap: 15px;
    }
}

@media screen and (max-width: 600px) {
    .div_main_tabs_heading {
        max-width: 515px;
        width: 100%;
        overflow-x: scroll;
    }

    .div_commonwrapper {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }


    .slick-dots li {
        width: 10px !important;
        height: 10px !important;
    }

    .slick-dots li button {
        width: 10px !important;
        height: 10px !important;
    }
}