.main_div_section_ourblogs {
    background: #f7f7f7;
    /* border-radius: 0px 0px 227px 227px; */
    padding-top: 60px;
    padding-bottom: 140px;
}

.image_card_ourblog {
    /* max-width: 347px; */
    height: 180px;
    width: 100%;
    /* height: 100%; */
    object-fit: cover;
    border-radius: 10px 10px 0px 0px;
}

.image_blog_card {
    max-height: 200px;
    width: 100%;
    height: 40%;
    object-fit: cover;
    border-radius: 10px 10px 0px 0px;
}

.image_card_recentarticle {
    max-width: 464px;
    width: 100%;
    border-radius: 10px 10px 0px 0px;
    height: 278px;
}

.div_image_card_topleft_text {
    position: relative;
}

.div_image_toplefttext_card {
    position: absolute;
    background: rgba(0, 0, 0, 0.33);
    border-radius: 6px;
    padding: 6px 16px;
    top: 10px;
    left: 10px;
}

.slick-dots li button {
    width: 14px;
    height: 14px;
}

.slick-dots li button:before {
    width: 14px;
    height: 14px;
    line-height: 16px;
}

.slick-dots li {
    width: 14px;
    height: 14px;
}


.div_col_oublogs_landing {
    /* border: 1px solid red; */
}


/* .div_main_carosel_ourblog>.row_main_landing_ourblogs>.slick-slider>.slick-list>.slick-track>.slick-slide+div {
    margin-right: 0px;
}

.div_main_carosel_ourblog>.row_main_landing_ourblogs>.slick-slider>.slick-dots li button:before {
    font-size: 14px !important;
    color: #29487d;
    opacity: 0 !important;
}

.div_main_carosel_ourblog>.row_main_landing_ourblogs>.slick-slider>.slick-dots .slick-active button:before {
    opacity: 1 !important;
    background-color: #29487d !important;
    border-radius: 50%;
}

.div_main_carosel_ourblog>.row_main_landing_ourblogs>.slick-slider>.slick-dots>li>button ::before {
    font-size: 14px !important;
}

.div_main_carosel_ourblog>.row_main_landing_ourblogs>.slick-slider>.slick-dots>li>button {
    border: 2px solid #29487d !important;
    border-radius: 50%;
    font-size: 14px !important;
}

.div_main_carosel_ourblog>.row_main_landing_ourblogs>.slick-slider>.slick-dots {
    bottom: -63px !important;
} */








.section_recent_articles {
    background: #f7f7f7;
    border-radius: 0px;
}

.p_tag_sectionrectionarticle {
    max-width: 905px;
    width: 100%;
}

.div_card_recentarticle {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    padding: 9px 9px;
}

.testest_ariceltestrt img {
    max-width: 164px;
    width: 100%;
}

.testest_ariceltestrt>figure>figure {
    max-width: 164px;
    width: 100%;
}

.testest_ariceltestrt>span {
    display: none !important;
}

.testest_ariceltestrt>h5 {
    display: none !important;
}

.img_imageRecentCard1 {
    max-width: 164px;
    width: 100%;
}

.left_section_card_recentarticel {
    max-width: 339px;
    width: 100%;
}

.div_card_rightsection_ourblogs {
    max-width: 758px;
    width: 100%;
}

.test img {
    /* max-width: 435px; */
    width: 100%;
    /* height: 450px; */
    border-radius: 10px 10px 10px 10px;


}

.test_ariceltestrt {}

.image_card_rightsection_ourblogs {
    /* max-width: 435px; */
    width: 100%;
    border-radius: 10px 10px 0px 0px;
}

.div_cirlecle_stepper {
    width: 38px;
    height: 38px;
    background: #ffffff;
    border-radius: 50%;
    border: 2px solid #29487d;
}

.linesetepper {
    width: 175px;
    border: 1px solid #29487d;
    transform: rotate(90deg);
}

.div_title_text_stepper {
    /* width: 98%; */
}

.div_text_stepper {
    height: 90px;
    overflow-y: scroll;
}

.div_text_stepper::-webkit-scrollbar {
    display: none;
}

.div_title_text_stepper::-webkit-scrollbar {
    display: none;
}

/* .div_card_main_blogcontent  > .div_html_content_backend p{
    fo
  
  } */


@media screen and (max-width: 1200px) {
    .div_Start_Slider_service_provider>.slick-slider>.slick-dots {
        /* right: 250px; */
    }
}

@media screen and (max-width: 900px) {
    .div_Start_Slider_service_provider>.slick-slider>.slick-dots {
        /* right: 100px; */
    }
}

@media screen and (max-width: 767px) {
    .left_section_card_recentarticel {
        max-width: 100%;
        width: 100%;
    }
}

@media screen and (max-width: 600px) {
    .div_Start_Slider_service_provider>.slick-slider>.slick-dots {
        /* right: 50px; */
    }

    .div_Start_Slider_service_provider>.slick-slider>.slick-dots {
        bottom: 25px !important;
    }

    /* .div_main_carosel_ourblog>.row_main_landing_ourblogs>.slick-slider {
        padding: 0px !important;
    } */
}