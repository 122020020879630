.bg-image-header-ourservices {
    background-image: url("../images/image_header_our_services.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}


.div_main_SectionOurServices {
    padding-top: 100px;
    padding-bottom: 100px;
}

.div_card_ourservices {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    padding: 14px 14px;
}

.image_card_ourservices {
    /* max-width: 271px; */
    width: 100%;
    border-radius: 10px 10px 0px 0px;
}

.div_btnviewmore {
    margin-top: 80px;
}

@media screen and (min-width: 1500px) {
    .div_grid_col_card_ourservices {
        width: 20% !important;
    }
}