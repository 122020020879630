/*
Template Name: Snacked Admin
Author: codervent
Email: codervent@gmail.com
File: style.css
*/

/* 
- General
- Header
- Sidebar
- Page Content
- Utilities
- Widgets
- Chips
- Email
- chat box
- Authentication
- swicther
- Responsive
 */
/* General */
body {
    font-size: 14px;
    color: #4c5258;
    letter-spacing: 0.5px;
    font-family: Roboto, sans-serif;
    background-color: #f7f8fa;
    overflow-x: hidden;
}

.wrapper {
    width: auto;
    position: relative;
}

a {
    text-decoration: none;
}

::placeholder {
    opacity: 1 !important;
    color: #29487d;
    font-weight: 400 !important;
    line-height: 1.4 !important;
}

/* Sidebar */
.sidebar-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 260px;
    height: 100%;
    background-color: #f7f8fa;
    background-clip: padding-box;
    border-right: 1px solid #e2e3e4;
    transition: all 0.2s;
    z-index: 16;
}

.sidebar-wrapper .sidebar-header {
    width: 260px;
    height: 60px;
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    bottom: 0;
    padding: 0 15px;
    z-index: 5;
    background: #f7f8fa;
    border-right: 1px solid #e2e3e4;
    border-bottom: 1px solid #e4e4e4;
    background-clip: padding-box;
    transition: all 0.2s;
}

.sidebar-wrapper .sidebar-header .logo-icon {
    width: 100px;
}

.sidebar-wrapper .sidebar-header .logo-text {
    font-size: 22px;
    margin-left: 10px;
    margin-bottom: 0;
    letter-spacing: 1px;
    color: #3461ff;
}

.toggle-icon {
    font-size: 26px;
    cursor: pointer;
    color: #3461ff;
    display: none;
}

.sidebar-wrapper ul {
    padding: 0;
    margin: 0;
    list-style: none;
    background: 0 0;
}

.sidebar-wrapper .metismenu {
    background: 0 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 10px;
    margin-top: 60px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.sidebar-wrapper .metismenu li+li {
    margin-top: 5px;
}

.sidebar-wrapper .metismenu li:first-child {
    margin-top: 5px;
}

.sidebar-wrapper .metismenu li:last-child {
    margin-bottom: 5px;
}

.sidebar-wrapper .metismenu>li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
}

.sidebar-wrapper .metismenu a {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 10px 15px;
    border-radius: 4px;
    font-size: 15px;
    color: #5f5f5f;
    outline-width: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    letter-spacing: 0.5px;
    border-left: 4px solid rgb(255 255 255 / 0%);
    transition: all 0.2s ease-out;
}

.sidebar-wrapper .metismenu a .parent-icon {
    font-size: 18px;
    line-height: 1;
}

.sidebar-wrapper .metismenu a .menu-title {
    margin-left: 10px;
}

.sidebar-wrapper .metismenu ul a {
    padding: 6px 15px 6px 15px;
    font-size: 15px;
    border: 0;
    border-left: 4px solid rgb(255 255 255 / 0%);
}

.sidebar-wrapper .metismenu ul a i {
    margin-right: 10px;
    font-size: 10px;
}

.sidebar-wrapper .metismenu ul {
    border: 1px solid #ffffff00;
    background: rgb(255 255 255 / 0%);
}

.sidebar-wrapper .metismenu ul ul a {
    padding: 8px 15px 8px 30px;
}

.sidebar-wrapper .metismenu ul ul ul a {
    padding: 8px 15px 8px 45px;
}

.sidebar-wrapper .metismenu .mm-active>a,
.sidebar-wrapper .metismenu a:active,
.sidebar-wrapper .metismenu a:focus,
.sidebar-wrapper .metismenu a:hover {
    color: #3461ff;
    text-decoration: none;
    background-color: rgb(255 255 255);
    border-left: 4px solid #3461ff;
    box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
}

.menu-label {
    padding: 20px 15px 5px 5px;
    color: #b0afaf;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 0.5px;
}

.metismenu .has-arrow:after {
    position: absolute;
    content: "";
    width: 0.5em;
    height: 0.5em;
    border-style: solid;
    border-width: 1.2px 0 0 1.2px;
    border-color: initial;
    right: 15px;
    transform: rotate(-45deg) translateY(-50%);
    transform-origin: top;
    top: 50%;
    transition: all 0.3s ease-out;
}

.wrapper.toggled .top-header .navbar {
    left: 70px;
}

/* .wrapper .toggled .page-content {
    margin-left: 70px;
} */

/* Page Content */

.page-content {
    margin-left: 260px;
    /* margin-top: 60px; */
    padding: 1.5rem;
    transition: all 0.2s;
}

/* Utilities */

.radius-10 {
    border-radius: 10px;
}

.radius-15 {
    border-radius: 15px;
}

.radius-30 {
    border-radius: 30px;
}

.font-13 {
    font-size: 13px;
}

.font-14 {
    font-size: 14px;
}

.font-20 {
    font-size: 20px;
}

.font-22 {
    font-size: 22px;
}

.font-24 {
    font-size: 24px;
}

.font-30 {
    font-size: 30px;
}

.font-35 {
    font-size: 35px;
}

.text-justify {
    text-align: justify;
}

.table-responsive {
    white-space: nowrap;
    height: 84vh;
}

.table-responsivetemp {
    /* min-height: 60vh; */
    /* max-height: 100vh; */
}

.cursor-pointer {
    cursor: pointer;
}

.breadcrumb-title {
    font-size: 20px;
    border-right: 1.5px solid #aaa4a4;
}

.page-breadcrumb .breadcrumb-item+.breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    font-family: LineIcons;
    content: "\ea5c";
}

.page-breadcrumb .breadcrumb li.breadcrumb-item {
    font-size: 16px;
}

/* Authentication */

.bg-login {
    /* background-image: url(../images/bg-login-img.png); */
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
}

.bg-register {
    /* background-image: url(../images/bg-register-img.png); */
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
}

.bg-forgot-password {
    /* background-image: url(../images/forgot-password.png); */
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
}

.bg-reset-password {
    /* background-image: url(../images/reset-password.png); */
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
}

.authentication-card {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    max-width: 60rem;
    height: 100vh;
}

.login-separater span {
    position: relative;
    top: 26px;
    margin-top: -10px;
    background: #fff;
    padding: 5px;
    font-size: 12px;
    color: #cbcbcb;
    z-index: 1;
}

/* swicther */

.btn-switcher {
    position: fixed;
    right: 0px;
    top: 40%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.header-colors-indigators .indigator {
    width: 50px;
    height: 50px;
    background-color: #f7f8fa;
    border-radius: 10px;
    cursor: pointer;
}

/* Responsive */

@media screen and (max-width: 1280px) {
    .email-header {
        height: auto;
    }

    .email-content {
        padding: 100px 0 0 0;
    }
}

@media screen and (min-width: 1025px) {
    .wrapper.toggled:not(.sidebar-hovered) .sidebar-wrapper .sidebar-header .logo-text {
        display: none;
    }

    /* .wrapper .toggled.sidebar-wrapper .sidebar-header .logo-text {
      display: none;
    } */

    .wrapper.toggled:not(.sidebar-hovered) .sidebar-wrapper {
        width: 800px;
    }

    .wrapper.toggled.sidebar-hovered .sidebar-wrapper {
        box-shadow: 0 0.3rem 0.8rem rgba(0, 0, 0, 0.12) !important;
    }

    .wrapper.toggled:not(.sidebar-hovered) .sidebar-wrapper .sidebar-header {
        width: 70px;
    }

    .wrapper.toggled:not(.sidebar-hovered) .sidebar-wrapper .sidebar-header .toggle-icon {
        display: none;
    }

    .wrapper.toggled:not(.sidebar-hovered) .sidebar-wrapper .sidebar-header {
        justify-content: center;
    }

    .wrapper.toggled:not(.sidebar-hovered) .sidebar-wrapper .sidebar-header {
        width: 70px;
    }

    .wrapper.toggled:not(.sidebar-hovered) .sidebar-wrapper .metismenu a {
        justify-content: center;
        border-left: 0px solid #3461ff;
    }

    .wrapper.toggled:not(.sidebar-hovered) .sidebar-wrapper .metismenu .menu-title {
        display: none;
    }

    .wrapper.toggled:not(.sidebar-hovered) .sidebar-wrapper .metismenu li ul {
        display: none;
    }

    .wrapper.toggled:not(.sidebar-hovered) .sidebar-wrapper .metismenu li.menu-label {
        display: none;
    }

    .wrapper.toggled:not(.sidebar-hovered) .sidebar-wrapper .metismenu .has-arrow:after {
        display: none;
    }


}

@media screen and (max-width: 1024px) {
    .sidebar-wrapper {
        width: 70px;
    }

    .sidebar-wrapper .sidebar-header {
        width: 70px;
    }

    .sidebar-wrapper .sidebar-header .sidebar-image {
        display: none;
    }

    .sidebar-wrapper .menu-list {
        display: none !important
    }

    .sidebar-wrapper.toggled {
        width: 260px;
    }

    .sidebar-wrapper.toggled .sidebar-header {
        width: 260px;
    }

    .sidebar-wrapper.toggled .sidebar-header .sidebar-image {
        display: block;
    }

    .sidebar-wrapper.toggled .menu-list {
        display: block !important
    }

    .page-content {
        margin-left: 70px;
    }

    .toggle-icon {
        display: block;
    }

    .top-header .navbar {
        left: 0px;
    }

    /* Toggeled */

    .wrapper.toggled .sidebar-wrapper {
        left: 0px;
    }

    .wrapper .sidebar-wrapper.toggled .page-content {
        margin-left: 70px;
    }

    .wrapper.toggled .top-header .navbar {
        left: 0px;
    }

    .wrapper.toggled .overlay {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: #000;
        opacity: 0.6;
        z-index: 12;
        display: block;
        cursor: move;
        transition: all 0.2s ease-out;
    }

    .top-header .navbar .searchbar {
        position: relative;
        width: 30%;
        display: none;
    }

    .top-header .navbar .full-searchbar {
        position: absolute;
        top: 0%;
        left: 0;
        right: 0%;
        width: 100%;
        height: 60px;
        background-color: #ffffff;
        border-bottom: 1px solid rgb(0 0 0 / 0%);
        background-clip: padding-box;
        display: flex !important;
        z-index: 1;
        align-items: center;
        justify-content: flex-start;
        padding: 0 1rem;
        -webkit-animation: 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 animdropdown;
        animation: 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 animdropdown;
    }

    .email-header {
        border-top-left-radius: 0.25rem;
    }

    .email-sidebar {
        left: -280px;
    }

    .email-content {
        margin-left: 0;
    }

    .email-header {
        left: 0;
    }

    .email-toggled .email-sidebar {
        left: 0;
    }

    .email-toggled .overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 250px;
        background: #000;
        opacity: 0.5;
        z-index: 9;
        display: block;
        cursor: move;
        transition: all 0.3s ease-out;
    }

    .authentication-card {
        padding: 1.5rem 0;
        height: auto;
    }

    .top-header .navbar .mobile-toggle-icon,
    .top-header .navbar .search-toggle-icon {
        display: block;
    }

    .top-header .navbar .searchbar .search-close-icon {
        display: block;
    }
}

@media screen and (max-width: 767px) {
    .top-header .navbar .dropdown-menu::after {
        display: none;
    }

    .top-header .navbar .dropdown {
        position: static !important;
    }

    .top-header .navbar .dropdown-menu {
        width: 100% !important;
    }

    .by-device-container {
        height: 210px;
    }

    .sidebar-wrapper .metismenu ul a {
        font-size: 10px;
    }
}

.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
    width: 150px;
    height: 150px;
}

.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
    width: 150px;
    height: 150px;
    border-radius: 10px;
}

.product-image-shadow {
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
}

.btn_IconButton {
    font-size: 30px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 21px;
    height: 175px;
    /*width: 150px !important; */
    width: 100%;
    /* height: 100%; */
    border-radius: 21px !important;
}

.product-image {
    height: 175px;
    width: 150px;
    object-fit: cover;
    border-radius: 10px;
}

.input_imageInOffer {
    width: 150px;
}

.upload-btn-wrapper {
    height: 175px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.upload-btn-wrapper input[type="file"] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    /* height: 175px;
    width: 150px; */
    height: 100%;
    width: 100%;
}

.testbtn {
    /* width: 400px !important;
    height: 250px !important; */
    width: 100% !important;
    height: 200px !important;
}

/* .stackPagination {
      align-items: center;
      display: flex;
      justify-content: center;
      align-items: center;
    } */

/* .stackPagination > ul {
      display: flex;
      align-items: center;
      justify-content: center;
      list-style-type: none;
      list-style: none;
      text-decoration: none;
      gap: 20px;
    }
    
    .stackPagination > ul li a {
      list-style-type: none;
      list-style: none;
      text-decoration: none;
      font-family: "Chivo";
      font-weight: 400;
      font-size: 24px;
      line-height: 29px;
      cursor: pointer;
      color: var(--primary);
    }
    
    .stackPagination > ul > .activeclassname {
      background: var(--primary);
      color: blue !important;
      border-radius: 50%;
      height: 42px;
      width: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    
    .stackPagination > ul > .activeclassname > a {
      color: blue !important;
    } */

/* .stackPagination > ul > .previous {
      display: none !important;
    }
    
    .stackPagination > ul > .nextClassName {
      display: none !important;
    } */

.pagination {
    margin: 15px auto;
    display: flex;
    list-style: none;
    outline: none;
    justify-content: flex-end;
    flex-wrap: wrap;
}

.pagination>.active>a {
    background-color: #47ccde;
    border-color: #47ccde;
    color: #fff;
    /* margin-top: 10px; */
    /* margin-bottom: 10px; */
}

.pagination>li {
    margin-top: 10px;
}

.pagination>li>a {
    border: 1px solid #47ccde;
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
    font-size: 14px;
    /* margin-bottom: 10px; */
}

.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus {
    background-color: #47ccde;
    border-color: #47ccde;
    outline: none;
}

.pagination>li>a,
.pagination>li>span {
    color: #47ccde;
}

.pagination>li:first-child>a,
.pagination>li:first-child>span,
.pagination>li:last-child>a,
.pagination>li:last-child>span {
    border-radius: unset;
}

.div_logout {
    border: 1px solid #DC3545;
    transition: all 0.2s ease-out;
    border-radius: 4px;
    cursor: pointer;
    color: #DC3545;
    text-decoration: none !important;

}

.div_logout:hover {
    background-color: #DC3545;
    color: white;
    transition: all 0.2s ease-out;
}