.parent_div_footer {
    background: #29487d;
    padding-top: 50px;
}

.logosvg {
    width: 100px;
}

.div_parent_logo_circle_text {
    gap: 20px;
}

.parent_div_circle_icon_text {
    gap: 20px;
}

.div_logo_svg_text {
    gap: 20px;
}

.circel_icon_div_footer {
    background: #385586;
    border-radius: 50px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.circel_icon_div {
    background: #385586;
    border-radius: 50px;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.img_circle_footer {
    width: 22px;
    height: 22px;
}

.img_circle {
    width: 15px;
    height: 15px;
}

.title_text_div {
    gap: 7px;
    width: 213px;
}

.div_icon_menu_ttile {
    height: 18px;
}

hr {
    width: 100%;
    height: 30px;
    margin-left: auto;
    margin-right: auto;
    background-color: #b7d0e2;
    border: 0 none;
    color: red;
}

.horizontal_line {
    border: 1px solid rgba(255, 255, 255, 0.28);
}

.div_line_footer_end {
    background: #29487d;
}

.div_text2023_text_policy {
    padding-left: 180px;
    padding-right: 180px;
}

.div_text_2023 {
    padding-top: 15px;
    padding-bottom: 15px;
}

.h6_standqutality {
    max-width: 345px;
    width: 100%;
}

.div_sectionfooter_second {
    display: flex !important;
}

.accordion_display {
    display: none;
}

.acordin_itemtest {
    border: none !important;


}

.acordin_itemtest:first-of-type {
    border-top-right-radius: 0 !important;
    border-top-left-radius: 0 !important;

}

.acordin_itemtest:last-of-type {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

@media screen and (min-width: 1300px) {
    .div_col_footer_second_other {
        width: 30% !important;
    }

    .div_col_footer_second_contatct {
        width: 40% !important;
    }
}


@media screen and (min-width: 992px) and (max-width: 1300px) {
    .div_col_footer_second_other {
        width: 27% !important;
    }

    .div_col_footer_second_contatct {
        width: 46% !important;
    }
}

@media screen and (max-width: 1500px) {
    .div_text2023_text_policy {
        padding-left: 80px !important;
        padding-right: 80px !important;
    }
}

@media screen and (max-width: 900px) {
    .div_text2023_text_policy {
        padding-left: 40px !important;
        padding-right: 40px !important;
    }
}

@media screen and (max-width: 576px) {
    .div_col_listitem_right_section {
        width: 32% !important;
    }

    .div_text2023_text_policy {
        flex-direction: column;
        gap: 30px;
        align-items: center;
    }

    .div_sectionfooter_second {
        display: none !important;
    }

    .accordion_display {
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin-top: 20px !important;
        margin-bottom: 20px !important;

    }

    .row_content_threecolumn {
        flex-direction: column;
        gap: 25px;
    }

    .opencontentaccordion {
        display: flex !important;
        flex-direction: column;
    }

    .opencontentaccordion_none {
        display: none !important;
    }
}

@media screen and (max-width: 500px) {
    .div_col_listitem_right_section {
        width: 48% !important;
        padding-bottom: 50px;
    }
}