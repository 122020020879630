.image_hammer_report {
    max-width: 703px;
    width: 100%;
}

.div_btn_sumit_btnreservation_report {
    gap: 10px;
}

.commombtnsumitreport {
    max-width: 306px;
    width: 100%;
    height: 50px;
}

.btn_sumit_report {
    background: #29487d;
    border: none !important;
}

.btn_make_reservation_report {
    background: #ffffff;
    border: 1px solid #29487d;
}

.widthtext2report {
    max-width: 637px;
    width: 100%;
}

.btn_text_report {
    padding: 12px 24px;
    background: #FFFFFF;
    border-radius: 10px;
    cursor: pointer;
}

.btn_upload_imagename_report {
    display: flex;
    align-items: center;
    gap: 12px
}

/* .div_selected_filename_report{
    max-width: 450px;
    width: 100%;
    overflow-x: scroll;
  
  } */
.div_selected_filename_report::-webkit-scrollbar {
    display: none;
}

.upload-btn-wrapper-report {
    position: relative;
    overflow: hidden;
    display: inline-block;
    max-width: 625px;
    width: 100%;
    height: 36px;
    border-radius: 10px;
}

.upload-btn-wrapper-report input[type="file"] {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 107px;
    height: 36px;

}