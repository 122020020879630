.autocomplet-input {
    width: 100%;
    height: 45px;
    padding-left: 10px;
    position: relative;
}

.autocomplet-input:focus-visible {
    outline: none !important;
}

.no-suggestions {
    position: absolute;
    color: #999;
    padding: 0.5rem;
}

.suggestions {
    position: absolute;
    border: 1px solid #999;
    border-top-width: 0;
    list-style: none;
    margin-top: 0;
    max-height: 140px;
    overflow-y: scroll;
    padding-left: 0;
}

.suggestions li {
    padding: 0.5rem;
    background-color: white;
    color: #29487d;
}

.suggestion-active,
.suggestions li:hover {
    background-color: #29487d;
    color: white;
    cursor: pointer;
}

.suggestions li:not(:last-of-type) {
    border-bottom: 1px solid #999;
}