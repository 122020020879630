@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body {
  margin: 0;
  font-family: "Roboto", sans-serif !important;
  background-color: #fff !important;
  font-style: normal !important;
}

h1 {
  font-size: 28px !important;
  line-height: 1.4 !important;
}

h2 {
  font-size: 20px !important;
  line-height: 1.4 !important;
}

h3 {
  font-size: 16px !important;
  line-height: 1.4 !important;
}

h4 {
  font-size: 16px !important;
  line-height: 1.4 !important;
}

h5 {
  font-size: 14px !important;
  line-height: 1.4 !important;
}

h6 {

  font-size: 12px !important;
  line-height: 1.4 !important;
}

p {
  font-size: 11px !important;
  line-height: 1.4 !important;
}

.w-600 {

  font-weight: 600 !important;
}

.w-500 {
  font-weight: 500 !important;

}

.w-400 {

  font-weight: 400 !important;
}


.fontSize64W6 {
  font-weight: 600;
  font-size: 64px;
  line-height: 105.16%;
}

.fontSize40W6 {
  font-weight: 600;
  font-size: 40px;
  line-height: 47px;
}

.fontSize32W6 {
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;
}

.fontSize24W5 {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
}

.fontSize24W4 {
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
}

.fontSize20W5 {
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
}

.fontSize20W4 {
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
}

.fontSize18W5 {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
}

.fontSize18W4 {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
}

.fontSize17W5 {
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;
}

.fontSize17W4 {
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
}

.fontSize16W6 {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}

.fontSize16W5 {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}

.fontSize16W4 {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}

.fontSize15W4 {
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
}

.fontSize14W5 {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}

.fontSize14W4 {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 16px !important;
}

.fontSize14W4Si {
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}

.fontSize13W4 {
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
}

.fontSize12W5 {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
}

.fontSize12W4Si {
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}

.fontSize12W4 {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}

.fontSize11W4 {
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
}

.fontSize10W5 {
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
}

.fontSize10W4 {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
}

.dark_grey {
  color: #979797;
}

.color97 {
  color: #979797;
}

.color29 {
  color: #29487d;
}

.colorWhite {
  color: #fff !important;
}

.colorb7 {
  color: #b7b7b7 !important;
}

.color3e {
  color: #3e3e3e !important;
}

.colorc8 {
  color: #c8c8c8 !important;
}

.card_bg {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.06);
}

.br10 {
  border-radius: 10px;
}

.br20 {
  border-radius: 20.5px !important;
}

.br50per {
  border-radius: 50% !important;
}

.texthovermodallist1:hover {
  color: #3e3e3e !important;
}

.texthovermodallist2:hover {
  color: #fff !important;
}

.bg-f9 {
  background-color: #f9f9f9 !important;
}

.bg-29 {
  background-color: #29487d !important;
}

.bg-f7 {
  background-color: #f7f7f7 !important;
}

.bg-fff {
  background-color: #ffffff !important;

}

.gap10 {
  gap: 10px;
}