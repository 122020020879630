.modaltest {
    /* position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center; */
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 999;
    /* left: 0;
    top: 0;
    right: 0;
    bottom: 0; */
}

.content {
    max-width: 500px;
    /* max-width: max-content; */
    width: 100%;
    /* width: 100%; */
    background: #ffffff;
    border-radius: 10px;
    padding: 25px 25px 36px 40px;
    /* height: max-content; */
    margin: 20px;
    max-height: 700px;
    /* min-height: 100px; */
    /* overflow-y: scroll; */

}

.div_contentlisofitemsmodal {
    height: 340px;
    overflow-y: scroll;
}

.div_content_contactus_modal {
    height: 500px;
    overflow-y: scroll;
}

.div_content_contactus_modal::-webkit-scrollbar {
    display: none !important;
}

.div_contentlisofitemsmodal::-webkit-scrollbar {
    display: none !important;
}

.content::-webkit-scrollbar {
    display: none !important;
}

.contentSecond {
    max-width: 732px;
    /* max-width: max-content; */
    width: 100%;
    /* width: 100%; */
    background: #ffffff;
    border-radius: 10px;
    padding: 25px 25px 36px 40px;
    height: max-content;
    margin: 20px;
}

.contentSecond::-webkit-scrollbar {
    display: none !important;
}

.inputfield_search {
    background: #ffffff;
    border: none;
    /* border-radius: 25px; */
    /* padding: 17px 32px; */
    /* max-width: 625px; */
    width: 100%;
    font-size: 12px !important;
    line-height: 1.6 !important;
}

.inputfield_search:focus-visible {
    outline: none !important;
}

.inputfield_search::placeholder {
    /* color: #c8c8c8 !important;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px; */
}

.btn_go {
    background: #29487d;
    border-radius: 0px 20.5px 20.5px 0px;
    border: none;
    padding: 12px 36px 12px 26px;
}

.div_linefilter_data:hover {
    background: #f9f9f9 !important;
}

.text_title_filter {
    padding: 12px 25px;
    cursor: pointer;
}

.texthovermodallist1:hover {
    color: #3e3e3e !important;
}

.texthovermodallist2:hover {
    color: #fff !important;
    cursor: pointer;
}

.footer {
    margin-top: 10px;
}

.div_linefilter_dataSecond:hover {
    background: #29487d !important;
}

.main_div_modal_contactus_left_section {
    display: flex;
    gap: 24px;
}

.div_modal_contactus_left_section {
    padding: 24px 33px 0px 33px;
    /* max-width: 208px; */
    /* width: 100%; */
    height: max-content;
    width: 208px;
}

.div_text_title_modal_left_section_ {
    max-width: 139px;
    width: 100%;
    gap: 5px;
}

.div_input_searchicon {
    padding: 12px 25px;
    max-width: 420px;
    width: 100%;
}

.div_sectionstepper_sectionexplore_category {
    gap: 60px;
}

.div_main_right_section_expolorre_categories {
    max-width: 748px;
    width: 100%;
}

.div_right_section_expolorre_categories {
    padding: 55px 70px 55px 55px;
}

.div_card_explore_category {
    width: 172px;
    /* height: 250px; */
    /* background: rgba(0, 0, 0, 0.58); */
    border-radius: 10px;
    position: relative;
}

.div_text_card_explore_category {
    position: absolute;
    top: 0px;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.58);
    border-radius: 10px;
}

.img_card_explore_category {
    max-width: 172px;
    width: 100%;
    /* height: 250px; */
}

.div_left_section_stepper {
    padding-left: 180px;
    /* border: 1px solid black; */
    width: 94%;
}

@media screen and (max-width: 1500px) {
    .div_left_section_stepper {
        padding-left: 80px !important;
        width: 100%;
    }

    .div_right_section_expolorre_categories {
        padding-right: 80px !important;
    }
}

@media screen and (max-width: 1200px) {
    .div_main_right_section_expolorre_categories {
        max-width: 400px;
        width: 100%;
    }
}

@media screen and (max-width: 992px) {
    .div_sectionstepper_sectionexplore_category {
        flex-direction: column !important;
    }

    .div_main_right_section_expolorre_categories {
        max-width: 100%;
    }

    .img_card_explore_category {
        max-width: 150px;
        width: 100%;
        /* height: 250px; */
    }

    .div_card_explore_category {
        max-width: 150px;
        width: 100%;
    }
}

@media screen and (max-width: 900px) {
    .div_left_section_stepper {
        padding-left: 40px !important;
    }

    .div_right_section_expolorre_categories {
        padding-right: 40px !important;
    }
}

@media screen and (max-width: 600px) {
    .div_left_section_stepper {
        padding-left: 20px !important;
    }

    .div_right_section_expolorre_categories {
        padding-left: 20px !important;
    }

    .div_modal_contactus_left_section {
        padding: 24px 15px 0px 15px;
    }

    .main_div_modal_contactus_left_section {
        flex-direction: column;
    }

    .content {
        padding: 25px 15px 36px 15px;
        /* height: 700px; */
        /* overflow-x: scroll; */
    }

    .content::-webkit-scrollbar {
        display: none !important;
    }

    .mainn_div_data_modal_contact_form {
        /* display: flex;
     
      max-width: 500px;
      width: 100%;
      flex-wrap: wrap;
      display: flex;
      gap: 10px; */
        padding: 10px;
    }

    /* .div-circle-icon-modal {
      width: 150px;
    } */
}

@media screen and (max-height: 650px) {
    .content {
        /* height: 400px; */
        /* overflow-y: scroll; */
    }

    .contentSecond {
        /* height: 500px; */
        /* overflow-y: scroll; */
    }

    .div_contentlisofitemsmodal {
        height: 250px;
    }

    .div_content_contactus_modal {
        height: 440px;
    }



    .content::-webkit-scrollbar {
        display: none !important;
    }

}

@media screen and (max-width: 600px) {

    .div_modal_contactus_left_section {
        padding: 24px 5px 0px 5px;
        width: 100%;
        height: 160px;


    }

    .div_col_icons_contact {
        width: 33.33% !important;

    }
}

@media screen and (max-height: 550px) {
    .content {
        /* height: 300px; */
        /* overflow-y: scroll; */
    }

    .contentSecond {
        /* height: 300px; */
        /* overflow-y: scroll; */
    }

    .content::-webkit-scrollbar {
        display: none !important;
    }

    .div_modal_contactus_left_section {
        padding: 24px 5px 0px 5px;
    }

    .div_content_contactus_modal {
        height: 300px;
    }

}

@media screen and (max-height: 450px) {
    .div_contentlisofitemsmodal {
        height: 110px;
    }

    .div_content_contactus_modal {
        height: 200px;
    }
}


@media screen and (max-height: 300px) {
    .content {
        /* height: 200px; */
        /* overflow-y: scroll; */
    }

    .div_contentlisofitemsmodal {
        height: 50px;
    }

    .div_content_contactus_modal {
        height: 100px;
    }
}

@media screen and (max-width: 500px) {

    .div_modal_contactus_left_section {
        height: 180px;


    }

}