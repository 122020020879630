.div_parent_section {
    padding-top: 63px;
    padding-bottom: 68px;
    background: #f7f7f7;
}

.span_easy_step {
    color: rgb(80, 104, 147) !important;
}

.div_rowdatacard_follow {
    width: 100%;
}

/* Section We Provide Service  */

.div_main_section_weprovide {
    border-radius: 0px 0px 0px 0px;
}

.div_firstrow_section_weprovide {
    margin-top: 63px !important;
}

.div_secondrow_section_weprovide {
    /* margin-top: 63px !important; */
}

.parent_div_card_weprovide {
    max-width: 340px;
    max-height: 300px;
    width: 100%;
    height: 100%;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    padding: 20px;
    margin-top: 10px;

}

.img_card_weproivde {
    width: 90%;
    height: 118px;
    object-fit: cover;
    border-radius: 10px;
    overflow: hidden;
}

.div_card_image_weprovide {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.div_subcard_weproivde {
    margin-top: 10px;
}

.p_text_card_weprovide {
    max-width: 274px;
    width: 100%;
}

.div_title_service_we_text {
    margin-left: 3rem !important;
    /* margin-top: 1.5rem !important; */
}

.img_imageserviceweprovide {
    border-radius: 10px;
    max-width: 694px;
    width: 100%;
    /* height: 295px; */
}

/* End of  Section We Provide Service*/

/* Start of  Section We  Year Experience*/
.div_main_section_year_experience {
    border-radius: 0px 0px 0px 0px;
}

.div_image_year_experience {
    display: flex !important;
    justify-content: flex-end !important;
}

.img_imageman_experience {
    max-width: 655px;
    width: 100%;
}

/* End of  Section We Year Experience*/

/* start of  Section We Broswe Popular*/

.div_parent_sectionPopular {
    margin-bottom: 100px;
    padding-top: 55px;
}

.div_text_browse_popular {
    max-width: 772px;
    width: 100%;
}

.div_card_carosel_popular {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 163px !important;
    width: 100%;
    cursor: pointer;
}

.div_card_image_popular {
    height: 60px;
    /* width: 60px; */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 43px;
}

.img_card_carosel_popular {
    width: 52px;
}


.div_Start_Slider_popular>.slick-slider>.slick-list>.slick-track>.slick-slide+div {
    margin-right: 0px;
}


.div_Start_Slider_popular>.slick-slider>.slick-dots li button:before {
    font-size: 14px !important;
    color: #29487d;
    opacity: 0 !important;
}

.div_Start_Slider_popular>.slick-slider>.slick-dots {
    display: flex;
    width: 100% !important;
}

.div_Start_Slider_popular>.slick-slider>.slick-dots .slick-active button:before {
    opacity: 1 !important;
    background-color: #29487d !important;
    border-radius: 50%;
}

/* .div_Start_Slider_popular>.slick-slider>.slick-dots>li>button ::before {
    font-size: 14px !important;
} */

.div_Start_Slider_popular>.slick-slider>.slick-dots>li>button {
    border: 2px solid #29487d !important;
    border-radius: 50%;
    /* font-size: 14px !important; */
}

/* end of  Section We Broswe Popular*/

/*  */

.bg-image-header1 {
    background-image: url("../images/bg-header-1.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.bg-image-header2 {
    background-image: url("../images/bg-header-2.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.bg-image-header3 {
    background-image: url("../images/bg-header-3.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.bg-image-header4 {
    background-image: url("../images/bg-header-4.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.bg-image-header5 {
    background-image: url("../images/bg-header-5.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.bg-image-header6 {
    background-image: url("../images/bg-header-6.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.bg-image-header7 {
    background-image: url("../images/bg-header-7.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.bg-image-header8 {
    background-image: url("../images/bg-header-8.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.bg-image-header-ourblogs {
    background-image: url("../images/backgroundImage_ourblog.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.bg-image-header-ourblogs-next {
    background-image: url("../images/ourblod-next-image.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.div_slide__content {
    position: relative;
    height: 100%;
    z-index: 2;
    /* padding: 139px 180px 80px 180px; */
    /* background: linear-gradient(90.25deg, rgba(0, 0, 0, 0.72) 3.31%, rgba(0, 0, 0, 0) 99.78%); */
    background: rgba(0, 0, 0, 0.5);
}

.div_slide__content_padding_simple {
    padding-top: 139px;
    padding-bottom: 80px;
}

.div_slide__content_padding_carosel {
    padding-top: 139px;
    padding-bottom: 160px;
}

.h2_headertext_bgimage {
    max-width: 425px;
    width: 100%;
    height: 40px;
    margin-top: 15px;
}

.h2_headertext_bgimage_ourblog {
    max-width: 650px;
    width: 100%;
    height: 40px;
    margin-top: 30px;
}

.div_div_inputpostal_btn_started {
    margin-top: 30px;
}

.div_inputpostal_btn_started {
    max-width: 364px;
    width: 100%;
    position: relative;
}

.input_field_landing_header {
    background: #ffffff;
    border-radius: 32.5px;
    /* max-width: 484px; */
    width: 99%;
    height: 48px;
    border: none !important;
    padding: 17px 0px 17px 20px;
}


.input_field_landing_header:focus-visible {
    outline: none !important;
}

.input_field_landing_header::placeholder {
    /* color: #979797 !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 1.4 !important; */
}

.banner_input {
    width: 100%;
    height: 45px;
    padding-left: 30px;
}

.banner_input:focus-visible {
    outline: none !important;
}

.btn_get_started {
    background: #29487d;
    /* background: white; */
    /* color: #29487d; */
    border-radius: 0px 32.5px 32.5px 0px;
    border: none !important;
    /* padding: 12px 17px 12px 17px; */
}

.div_Start_Slider_service_provider>.slick-slider>.slick-dots li button:before {
    font-size: 14px !important;
    color: #ffffff;
    opacity: 0 !important;
}

.div_Start_Slider_service_provider>.slick-slider>.slick-dots .slick-active button:before {
    opacity: 1 !important;
    background-color: #f7f7f7 !important;
    border-radius: 50%;
}

.div_Start_Slider_service_provider>.slick-slider>.slick-dots>li>button ::before {
    font-size: 14px !important;
}

.div_Start_Slider_service_provider>.slick-slider>.slick-dots {
    bottom: 20px !important;
    /* right: 732px; */
}

.div_Start_Slider_service_provider>.slick-slider>.slick-dots>li>button {
    border: 2px solid #ffffff !important;
    border-radius: 50%;
    font-size: 17px !important;
}

/*  */
.tex1_headercarsole_landing {
    text-decoration: none;
}

.div_backbtn {
    cursor: pointer;
    width: 20px;
}

.div_backbtn:hover {
    color: #29487d !important;

}

.testcoloricon {
    color: #3e3e3e !important;

}

.testcoloricon:hover {
    color: #29487d !important;

}

@media screen and (max-width: 1880px) {
    .div_Start_Slider_service_provider>.slick-slider>.slick-dots {
        bottom: 50px !important;
        /* right: 500px; */
    }

}

@media screen and (max-width: 1500px) {
    .div_Start_Slider_service_provider>.slick-slider>.slick-dots {
        bottom: 50px !important;
        /* right: 400px; */
    }

}

@media screen and (max-width: 1200px) {
    .div_image_year_experience {
        margin-top: 30px;
        display: flex !important;
        justify-content: center !important;
    }

    .div_title_service_we_text {
        margin-left: 0rem !important;
    }
}

@media screen and (max-width: 600px) {
    .div_title_service_we_text {
        margin-top: 2rem !important;
    }

    .title_headercarsole_landing {
        font-weight: 500 !important;
        font-size: 20px !important;
        line-height: 1.4 !important;
    }

    .div_slide__content_padding_simple {
        padding-top: 100px;
        padding-bottom: 80px;
    }

    .div_slide__content_padding_carosel {
        padding-top: 100px;
        padding-bottom: 80px;
    }

    .h2_headertext_bgimage,
    .tex1_headercarsole_landing {
        font-size: 14px !important;
    }

    .h2_headertext_bgimage {
        min-height: 70px;
    }

    .div_col_follow_easystep {
        /* border:1px solid red; */
        width: 50% !important;
        /* padding: 1px; */
        padding-left: 2px !important;
        padding-right: 2px !important;

    }
}

@media screen and (max-width: 380px) {

    .div_card_carosel_popular {

        max-width: 145px !important;
        width: 100%;
    }

    .div_card_image_popular {
        height: 45px;
    }
}